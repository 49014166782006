<template>
    <base-section id="marketing">
        <v-container>
            <v-row align="center">
                <template v-for="(card, i) in cards">
                    <v-col cols="12" lg="6" :key="`left-col-${i}`">
                        <v-card
                            v-if="card.textPosition === 'left'"
                            :color="card.color"
                            class="pa-2"
                        >
                            <v-card-title>
                                <v-icon
                                    :color="
                                        card.dark
                                            ? 'blue-grey lighten-5'
                                            : 'primary'
                                    "
                                    size="80"
                                    >{{ card.headerIcon }}</v-icon
                                >
                            </v-card-title>
                            <v-card-text>
                                <base-info-card v-bind="card" />
                            </v-card-text>
                        </v-card>
                        <v-img v-else :src="card.image" />
                    </v-col>
                    <v-col cols="12" lg="6" :key="`right-col-${i}`">
                        <v-card
                            v-if="card.textPosition === 'right'"
                            :color="card.color"
                            class="pa-2"
                        >
                            <v-card-title>
                                <v-icon
                                    :color="
                                        card.dark
                                            ? 'blue-grey lighten-5'
                                            : 'primary'
                                    "
                                    size="80"
                                    >{{ card.headerIcon }}</v-icon
                                >
                            </v-card-title>
                            <v-card-text>
                                <base-info-card v-bind="card" />
                            </v-card-text>
                        </v-card>
                        <v-img v-else :src="card.image" />
                    </v-col>
                </template>
            </v-row>
        </v-container>
    </base-section>
</template>

<script>
export default {
    name: "SectionMarketing",

    data: () => ({
        cards: [
            {
                headerIcon: "mdi-palette",
                title: "USER-FRIENDLY DESIGN",
                text: "Whether you are an applicant or a property manager, this site is easy to navigate. We use cutting-edge technology to design and implement new software. We believe that software should fix problems and help attract more applicants to your listings. As a property manager, you will have access to a simple-to-navigate dashboard to help you quickly see how many applicants are interested in your property and how many units are available at a given time.",
                image: "https://pacific-screening.imgix.net/rw-apply-oregon/apply-oregon-dashboard-1.png",
                textPosition: "left",
                dark: false,
                color: "white",
            },
            {
                headerIcon: "mdi-timer-outline",
                title: "FAST AND FREE PORTFOLIO SETUP",
                text: "Property Managers can set up free listings for entire properties or available units within seconds. You can add property descriptions, list out services and features of the property, add images, link to maps, upload screening criteria, toggle availability, and sync with an existing listings page. You also have the ability to add a Rent Well Certified Seal to your listings. This will help Rent Well graduates navigate to your lists quickly.",
                image: "https://pacific-screening.imgix.net/rw-apply-oregon/apply-oregon-edit-unit.png?q=60&rect=0,120,1352,855&fit=crop&crop=top&h=335&w=655",
                textPosition: "right",
                dark: true,
                color: "primary",
            },
        ],
    }),
};
</script>
